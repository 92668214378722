// Dependencies
import ExcelJS from 'exceljs'
import FileSaver from 'file-saver'

// Class Export
export class ExcelBook {
	private workbook: ExcelJS.Workbook

	constructor() {
		this.workbook = new ExcelJS.Workbook()
		this.workbook.created = new Date()
	}

	public getWorkBook() {
		return this.workbook
	}

	public getWorkSheet(name: string) {
		return this.workbook.getWorksheet(name)
	}

	public removeWorkSheet(workSheet: ExcelJS.Worksheet) {
		this.workbook.removeWorksheet(workSheet.id)
		return this
	}

	public async saveFileAsCSV(fileName: string) {
		const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
		const buffer = await this.workbook.csv.writeBuffer()
		const blob = new Blob(['\uFEFF', buffer], { type: fileType })
		FileSaver.saveAs(blob, `${ fileName }.csv`)
	}

	public async saveFileAsXLSX(fileName: string) {
		const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
		const buffer = await this.workbook.xlsx.writeBuffer()
		const blob = new Blob([buffer], { type: fileType })
		FileSaver.saveAs(blob, `${ fileName }.xlsx`)
	}

	public setCreator(creator: string) {
		this.workbook.creator = creator
		return this
	}

	public setLastModifiedBy(by: string) {
		this.workbook.lastModifiedBy = by
		return this
	}

	public setLastPrintedOn(date: Date) {
		this.workbook.lastPrinted = date
		return this
	}

	public setModifiedWhen(date: Date) {
		this.workbook.modified = date
		return this
	}
}