export class ActionItem {
	private disabled?: boolean
	private icon?: string | [string, string]
	private key: string
	private permission: string
	private text?: string
	private type: string
	private variant: string

	constructor(key: string, type: string) {
		this.key = key
		this.type = type
	}

	public getText() {
		return this.text
	}

	public setDisable(bool: boolean): ActionItem {
		this.disabled = bool
		return this
	}

	public setIcon(icon: string | [string, string]): ActionItem {
		this.icon = icon
		return this
	}

	public setPermission(permission: string): ActionItem {
		this.permission = permission
		return this
	}
	
	public setText(text: string): ActionItem {
		this.text = text
		return this
	}
	
	public setVariant(variant: string): ActionItem {
		this.variant = variant
		return this
	}
}