// Classes
import { ActionField }       from '@/Classes/Records/ActionField'
import FieldsOptions         from '@/Classes/Records/FieldsOptions'
import { ItemMissingFields } from '@/Classes/Records/ItemMissingFields'

// Constants
import { Component } from '@/Constants/Global/Component'

// Class Export
export class BuilderManager {
	private fields: Array<any> = []
	private items: Array<any> = []
	private actions: Array<string> = []

	constructor(data: any[], actions?: (any[] | any)) {
		// Generar los campos que serán mostrados como cabezera en el componente 'DataTable'.
		if (data.length > 0) {
			for (const fieldName of Object.keys(data[0])) {
				const field = { key: fieldName, label: fieldName, thStyle: Component.VueComponents.Global.DataTable.DefaultThStyle }
				this.fields.push(field)
			}
		}
	
		// Opcionalmente, pueden ser agregado campos de 'acciones'.
		if (actions !== undefined) {
			this.actions = []
			for (const action of actions) {
				if (action?.field) {
					this.fields.push({ IS_ACTION: true, ...action.field })
					this.actions.push(action.field.key)
				}
			}
		}
	
		// Generar los items o filas que serán mostradas en el cuerpo del componente 'DataTable'.
		for (const dataRow of data) {
			const row: any = {}

			// Agregar las propiedades que conformarán un 'Item'.
			for (const entry of Object.entries(dataRow)) {
				row[entry[0]] = entry[1]
				row._showDetails = false
			}
	
			// Opcionalmente, pueden ser agregado campos de 'acciones'.
			if (actions !== undefined) {
				for (const action of actions) {
					if (action?.items) {
						row[action.field.key] = []
						for (const item of action.items) {
							// Propiedad '$onItemCondition' permite retornar un objeto segun la resolución de la función con el parametro 'item'.
							if ('$onItemCondition' in item) {
								const resolved = item.$onItemCondition(row)
								this._pushActionsToItemArray(row, action, resolved)
							}
							// Validar las propiedades de los objetos directamente, verificando el tipo y agregar las opciones al objeto 'item' finalmente.
							else {
								this._pushActionsToItemArray(row, action, item)
							}
						}
					}
				}
			}

			this.items.push(row)
		}
	}

	private _addMissingOptionsFields(fields: Array<any>, options: any) {
		// Eliminar de 'options', los campos que ya se encuentran.
		for (const $f of fields) {
			if ($f.key in options) {
				delete options[$f.key]
			}
		}

		// Agregar a 'fields', los campos que quedaron en 'options'.
		for (const $o of Object.keys(options)) {
			fields.push({ key: $o, ...options[$o] })
		}
	}

	private _parseActionsOptionsForButtonType(row: any, item: any) {
		// Propiedades para una Acción de tipo 'button'.
		const options: any = {
			key: item.key,
			text: item?.$textFrom ? row[item.$textFrom] : item.text,
			type: item.type,
			variant: item?.$colorBy ? item.$colorFrom[row[item.$colorBy]] : item?.variant ? item.variant : 'gray'
		}
		
		// Propiedades Opcionales.
		if ('enumFrom' in item) options.enum = item.enumFrom[row[item.enumBy]]
		if ('permission' in item) options.permission = item.permission
		if ('sortBy' in item) options.sort = item.sortBy

		return options
	}

	private _pushActionsToItemArray(row: any, actionParent: any, item: any) {
		// Propiedades con los tipos de 'elementos' predefinidos que pueden ser mostrados en el componente <DataTable>.
		if (item) {
			if (item.type === 'button') {
				const parsedOptions = this._parseActionsOptionsForButtonType(row, item)
				row[actionParent.field.key].push(parsedOptions)
			}
			else if (item.type === 'check') {
				row[actionParent.field.key].push({ checked: false, ...item })
			}
			else if (item.type === 'icon') {
				row[actionParent.field.key].push(item)
			}
		}
	}

	public forEachCell(items: Array<any>, executor: (key: string, item: any) => void) {
		for (const item of items) {
			for (const key in item) {
				executor(key, item)
			}
		}
	}
	
	public forEachElement(elements: Array<any>, executor: (e: any) => void) {
		for (const element of elements) executor(element)
	}

	public generateDetailsFromItemsValueExcepts(items: Array<any>, skipFields: Array<string>) {
		for (const item of items) {
			item._details = {}
			for (const itemKey in item) {
				if (itemKey === '_details' || itemKey === '_showDetails') continue
				const skipProp = skipFields.find((x) => x === itemKey)
				if (skipProp) continue
				item._details[itemKey] = item[itemKey]?.value
			}
		}
	}

	public getElements() {
		return { fields: this.fields, items: this.items, actions: this.actions }
	}

	public parseFieldsOptions(fields: Array<any>, options: FieldsOptions) {
		if (fields !== undefined) {
			for (const $f of fields) {
				// Verificar que existen opciones asociadas al campo.
				if (options.options?.[$f.key]) {
					// Las opciones especificas para el campo actual.
					const $options = options.options[$f.key]
					
					// Iterar entras las opciones posibles.
					for (const $o of Object.entries($options)) {
						const param: { k: string, v: any } = { k: $o[0], v: $o[1] }
						$f[param.k] = param.v;
					}
				}
			}
			// Agregar los campos que no se encuentran en 'fields' pero si en 'options'
			this._addMissingOptionsFields(fields, options.options)
		}
	}

	public parseItemsDetail(items: Array<any>, formatters: ParseItemsDetailFormattersObject) {
		for (const item of items) {
			item._details = {}
			for (const key in formatters) {
				if (Array.isArray(formatters[key])) {
					item._details[key] = formatters[key]
				}
				else {
					const f = formatters[key] as Function
					item._details[key] = f(item)
				}
			}
		}
	}

	public parseItemsFormatters(items: Array<any>, formatters: any) {
		for (const item of items) {	
			for (const $o of Object.entries(item)) {
				const param = {k: $o[0], v: $o[1]}
				
				// Verificar si existe alguna propiedad en formatters.
				if (formatters?.[param.k]) {
					item[param.k] = formatters[param.k](param.v, item)
				}
			}
		}
	}

	public parseItemsMissingFields(items: Array<any>, params: ItemMissingFields) {
		const customValues = { autoIncrement: 0 }
		for (const itemtem of items) {
			for (const key of Object.keys(params.getFields())) {
				if (!itemtem?.[key]) {
					const value = params.getField(key).text
					if (value === 'AUTO_INCREMENT') {
						itemtem[key] = ++customValues.autoIncrement
					}
					else {
						itemtem[key] = value
					}
				}
			}
		}
	}

	public rearrangeFields(fields: Array<any>, rearrange: Array<string>) {
		let index = 0
		for (const $k of rearrange) {
			for (const $f of fields) {
				if ($k === $f.key) {
					const i = fields.findIndex(x => x.key === $k)
					fields.splice(i, 1)
					fields.splice(index++, 0, $f)
					break
				}
			}
		}
	}

	public removeFields(fields: Array<any>, names: Array<string>) {
		for (let i = 0; i < fields.length; i++) {
			const $f = fields[i]
			if (names.includes($f.key)) {
				fields.splice(i, 1)
				i--
			}
		}
	}

	public removeItems(items: Array<any>, options: Array<{ if?: boolean, field: string, value: any }>) {
		for (const $option of options) {
			const { field, value } = $option
			for (let i = 0; i < items.length; i++) {
				const item = items[i]
				if (field in item) {
					if ('if' in $option && $option.if === true) {
						if (item[field] === value) {
							items.splice(i, 1)
							i--
						}
					}
					else {
						if (item[field] === value) {
							items.splice(i, 1)
							i--
						}
					}
				}
			}
		}
	}

	public showFields(fields: Array<any>, names: Array<string>) {
		for (let i = 0; i < fields.length; i++) {
			const field = fields[i]
			if (!names.includes(field.key)) {
				fields.splice(i, 1)
				i--
			}
		}
	}
}

export interface ParseItemsDetailFormattersObject {
	[key: string]: ((item: any) => string | Array<string>) | Array<ActionField>
}