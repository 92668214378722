// Constants
import { Component } from '@/Constants/Global/Component'

// Class Export
export default class FieldsOptions {
	private _lastKey: string
	public options: { [key: string]: any }

	constructor() {
		this.options = {}
	}

	private _getLastOption() {
		return this.options[this._lastKey]
	}

	public add(key: string, label?: string) {
		this._lastKey = key
		this.options[this._lastKey] = { label }
		this.setStyle(Component.VueComponents.Global.DataTable.DefaultThStyle)
		return this
	}

	public ignoreSpecialChars() {
		this._getLastOption().ignoreSpecialChars = true
		return this
	}

	public isSortable() {
		this._getLastOption().sortable = true
		return this
	}

	public setAlign(field?: string, item?: string) {
		this._getLastOption().align = { field, item }
		return this
	}
	
	public setContentStyle(contentStyle: string, callback?: (item: any) => boolean) {
		const option = this._getLastOption()
		option.contentStyle = contentStyle
		if (callback) option.contentCallback = callback
		return this
	}
	
	public setIcon(icon: string | [string, string]) {
		this._getLastOption().icon = icon
		return this
	}

	public setSearchKey(key: string) {
		this._getLastOption().searchKey = key
		return this
	}
	
	public setSortDirection(sort: string) {
		this._getLastOption().sortDirection = sort
		return this
	}

	public setStyle(style: object) {
		const option = this._getLastOption()
		if (!option.thStyle) option.thStyle = { ...style }
		return this
	}

	public setStyleTh(style: any) {
		const option = this._getLastOption()
		if (!option.thStyle) option.thStyle = {}
		if ('width' in style) option.thStyle.width = style.width
		return this
	}

	public setTitle(title: string) {
		this._getLastOption().title = title
		return this
	}

	public showDetailsButton() {
		this._getLastOption().showDetailsButton = true
		return this
	}
}