// Dependencies
import ExcelJS from 'exceljs'

type CellIndexerKey = string | number
type WorkSheetStates = 'visible' | 'hidden' | 'veryHidden'

// Class Export
export class ExcelSheet {
	private worksheet: ExcelJS.Worksheet

	constructor(workbook: ExcelJS.Workbook, name: string) {
		this.worksheet = workbook.addWorksheet(name)
	}

	private _cell(cellKey: ExcelJS.Cell | CellIndexerKey) {
		if (typeof cellKey === 'string' || typeof cellKey === 'number') return this.worksheet.getCell(cellKey)
		return cellKey
	}

	public getCellText(cell: ExcelJS.Cell) {
		return cell.text
	}

	public getColumnCount(actualCount: boolean) {
		return actualCount
			? this.worksheet.actualColumnCount
			: this.worksheet.columnCount
	}

	public getLastRow() {
		return this.worksheet.lastRow
	}

	public getRowCount(actualCount: boolean) {
		return actualCount
			? this.worksheet.actualRowCount
			: this.worksheet.rowCount
	}

	public getRowLength(row: ExcelJS.Row) {
		return row.cellCount
	}

	public getRowNumValues(row: ExcelJS.Row) {
		return row.actualCellCount
	}

	public getWorkSheet() {
		return this.worksheet
	}

	public setAutoFilter(filter: ExcelJS.AutoFilter) {
		this.worksheet.autoFilter = filter
		return this
	}

	public setCellAlignment(cellKey: ExcelJS.Cell | CellIndexerKey, h?: ExcelJS.Alignment['horizontal'], v?: ExcelJS.Alignment['vertical']) {
		const cell = this._cell(cellKey)
		const alignment = cell.alignment
		cell.alignment = { ...alignment, horizontal: h, vertical: v }
		return this
	}

	public setCellFontBold(cellKey: ExcelJS.Cell | CellIndexerKey, bold = true) {
		const cell = this._cell(cellKey)
		const font = cell.font
		cell.font = { ...font, bold }
		return this
	}

	public setCellFontColor(cellKey: ExcelJS.Cell | CellIndexerKey, color: string) {
		const cell = this._cell(cellKey)
		const font = cell.font
		cell.font = { ...font, color: { argb: color } }
		return this
	}

	public setCellSolidFill(cellKey: ExcelJS.Cell | CellIndexerKey, color: string) {
		const cell = this._cell(cellKey)
		cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: color } }
		return this
	}

	public setCellValue(cellKey: ExcelJS.Cell | CellIndexerKey, value: any) {
		const cell = this._cell(cellKey)
		cell.value = value
		return this
	}

	public setColumns(columns: Array<ExcelJS.Column>) {
		this.worksheet.columns = columns
		return this
	}

	public setColumnHeader(column: ExcelJS.Column, header: string) {
		column.header = header
		return this
	}

	public setColumnHidden(column: ExcelJS.Column, hide: boolean) {
		column.hidden = hide
		return this
	}

	public setColumnWidth(column: ExcelJS.Column, width: number) {
		column.width = width
		return this
	}

	public setRowCellValue(row: ExcelJS.Row, cellKey: CellIndexerKey, value: any) {
		row.getCell(cellKey).value = value
		return this
	}

	public setRowHeight(row: ExcelJS.Row, height: number) {
		row.height = height
		return this
	}

	public setRowHidden(row: ExcelJS.Row, hide: boolean) {
		row.hidden = hide
		return this
	}

	public setRowValuesFromArray(row: ExcelJS.Row, values: Array<any>) {
		row.values = values
		return this
	}

	public setRowValuesFromObject(row: ExcelJS.Row, values: { [key: string]: ExcelJS.CellValue }) {
		row.values = values
		return this
	}

	public setState(state: WorkSheetStates) {
		this.worksheet.state = state
		return this
	}
}