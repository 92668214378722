// Classes
import { ActionItem } from '@/Classes/Records/ActionItem'

// Constants
import { Component } from '@/Constants/Global/Component'

// Enum Export
export enum ActionSortMode {
	ROW = '$row',
	SELF = '$self'
}

// Enum Export
export enum ActionCallbackName {
	ITEM_CONDITION = '$onItemCondition'
}

// Class Export
export class ActionField {
	private itemIndex: number = -1
	public field: { [key: string]: any } = { }
	public items: any[] = []

	constructor(key: string, label: string) {
		this.field.key = key
		this.field.label = label
		this.setStyle(Component.VueComponents.Global.DataTable.DefaultThStyle)
	}

	private _getCurrentItem(): any {
		return this.items[this.itemIndex]
	}

	public addItem(key: string, type: 'button' | 'check' | 'icon'): ActionField {
		this.itemIndex++
		this.items.push({ key, type })
		return this
	}

	public isDisabled(): ActionField {
		const item = this._getCurrentItem()
		item.disabled = true
		return this
	}

	public isSortable(): ActionField {
		this.field.sortable = true
		return this
	}

	public on(name: string, callback: (item: any) => (ActionItem | void)): ActionField {
		this.itemIndex++
		this.items.push({ })
		this.items[this.itemIndex][name] = callback
		return this
	}

	public setColor(fieldName: string, from: any): ActionField {
		const item = this._getCurrentItem()
		item.$colorBy = fieldName
		item.$colorFrom = from
		return this
	}
	
	public setEnumerable(fieldName: string, from: any): ActionField {
		const item = this._getCurrentItem()
		item.enumBy = fieldName
		item.enumFrom = from
		return this
	}

	public setIcon(icon: string | [string, string]): ActionField {
		const item = this._getCurrentItem()
		item.icon = icon
		return this
	}

	public setPermission(key: string): ActionField {
		const item = this._getCurrentItem()
		item.permission = key
		return this
	}

	public setSort(...sortBy: Array<[string, string]>): ActionField {
		const item = this._getCurrentItem()
		item.sortBy = []
		for (const s of sortBy) item.sortBy.push({ key: s[0], from: s[1] })
		return this
	}

	public setStyle(style: object): ActionField {
		this.field.thStyle = style
		return this
	}

	public setText(text: string): ActionField {
		const item = this._getCurrentItem()
		item.text = text
		return this
	}
	
	public setTextFrom(fieldName: string): ActionField {
		const item = this._getCurrentItem()
		item.$textFrom = fieldName
		return this
	}

	public setTitle(title: string): ActionField {
		const item = this._getCurrentItem()
		item.title = title
		return this
	}

	public setType(type: 'normal' | 'switch' = 'normal'): ActionField {
		const item = this._getCurrentItem()
		item.checkType = type
		return this
	}

	public setVariant(variant: string): ActionField {
		const item = this._getCurrentItem()
		item.variant = variant
		return this
	}
}